import React, { useState, useMemo } from 'react';
// import PropTypes from 'prop-types';
import { Carousel } from 'front-end-library';
import FullScreenLayout from '../FullScreenLayout';

import { domain } from '../../config';

import './style.css';

const findByName = (items, name) => items.filter((item) => (name === item.name))

const Images = ({ items, showFirst }) => {
  const [active, setActive] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  // Favour PROPOSED images over existing if 2 identically named images
  const filteredImages = useMemo(
    () => {
      return items;
      // if (items === null) return [];
      // return items.filter(({ name, fileName, type, mime }) => (
      //   mime !== null
      //   && name !== 'Thumbnail'
      //   && type === 'cgi')
      //   && (
      //     findByName(items, name).length === 1
      //     || fileName.includes('PROPOSED')
      //   )
      // );
    },
    [items],
  );

  const carouselItems = useMemo(
    () => filteredImages.map((item, index) => {
      let { altUrl } = item;
      if (!altUrl) {
        const altImage = findByName(items, item.name).find(({ id }) => id !== item.id);
        // Add the 'existing' image as altUrl field if there is one
        altUrl = altImage ? `${domain}${altImage.url}` : undefined;
      }
      return {
        url: `${domain}${item.url}`,
        altUrl,
        title: item.name,
        key: item.id,
        index,
      }
    }),
    [filteredImages],
  );

  const renderImages = () => filteredImages.map((item, index) => (
    <div
      onClick={() => {
        setActive(true);
        setImageIndex(index);
      }}
      onKeyPress={() => {
        setActive(true);
        setImageIndex(index);
      }}
      key={item.id}
      className="ffl__col-12--md ffl__col-6"
      role="button"
      tabIndex="0"
      style={{ cursor: 'pointer' }}
      data-slide={index}
    >
      <img
        style={{ width: '100%' }}
        src={`${domain}${item.url}`}
        alt={item.fileName}
      />
    </div>
  ));

  return (
    <>
      {showFirst && filteredImages.length
        ? (
          <div
            onClick={() => {
              setActive(true);
              setImageIndex(0);
            }}
            onKeyPress={() => {
              setActive(true);
              setImageIndex(0);
            }}
            key={filteredImages[0].id}
            role="button"
            tabIndex="0"
            style={{ cursor: 'pointer' }}
            data-slide={0}
          >
            <img
              style={{ width: '100%' }}
              src={`${domain}${filteredImages[0].url}`}
              alt={filteredImages[0].fileName}
            />
          </div>
        )
        : (
          <div id="images" className="row justify-between flex-wrap">
            {renderImages()}
          </div>
        )}
      {active && carouselItems && (
        <FullScreenLayout closeFullscreen={() => setActive(false)}>
          <Carousel totalItems={carouselItems} slideIndex={imageIndex} />
        </FullScreenLayout>
      )}
    </>
  );
};

// Images.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.object),
//   showFirst: PropTypes.bool,
// };

// Images.defaultProps = {
//   items: [],
//   showFirst: false,
// };

export default Images;
