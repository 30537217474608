import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

const Link = styled(RouterLink)`
  color: ${({ theme }) => theme.palette.primary};
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export default Link;
