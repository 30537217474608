import React, { useState } from 'react';

import FullScreenLayout from '../FullScreenLayout';

import config from '../../config';

const VideoItem = ({ name, url, mimeType }) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          setActive(true);
        }}
        role="button"
        style={{ cursor: 'pointer' }}
      >
        <video style={{ width: '100%', height: '100%' }} autoPlay>
          <source
            type={mimeType}
            src={`${config.urls.backendApi}/${url}`}
          />
        </video>
      </div>
      {active && (
        <FullScreenLayout closeFullscreen={() => setActive(false)}>
          <video controls style={{ width: '100%', height: '100%' }}>
            <source
              type={mimeType}
              src={`${config.urls.backendApi}/${url}`}
            />
          </video>
        </FullScreenLayout>
      )}
    </>
  );
};

// Images.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.object),
//   showFirst: PropTypes.bool,
// };

// Images.defaultProps = {
//   items: [],
//   showFirst: false,
// };

export default VideoItem;
