import React, { useState } from 'react';
import { Pannellum } from 'pannellum-react';

import FullScreenLayout from '../FullScreenLayout';

import config from '../../config';

const PanoImage = ({ name, url }) => {
  const [active, setActive] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          setActive(true);
        }}
        role="button"
        style={{ cursor: 'pointer' }}
      >
        <img
          alt="A Pano Image"
          style={{ width: '100%' }}
          src={`${config.urls.backendApi}/${url}`}
        />
      </div>
      {active && (
        <FullScreenLayout closeFullscreen={() => setActive(false)}>
          <Pannellum
            width="100%"
            height="100%"
            image={`${config.urls.backendApi}/${url}`}
            pitch={10}
            yaw={180}
            hfov={110}
            autoLoad
            onLoad={() => {
              console.log('panorama loaded');
            }}
          />
        </FullScreenLayout>
      )}
    </>
  );
};

// Images.propTypes = {
//   items: PropTypes.arrayOf(PropTypes.object),
//   showFirst: PropTypes.bool,
// };

// Images.defaultProps = {
//   items: [],
//   showFirst: false,
// };

export default PanoImage;
