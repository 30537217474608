import React, { useEffect, useRef, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import noop from 'lodash/noop';

import close from '../../../assets/img/close.png';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-content: center;
  background: #004E63; // TODO: Add to theme

  h3 {
    flex: 1;
    color: white;
    margin: 0;
  }
`;

const CloseButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 10px;
`;

const instanceDefault = { remove: noop };
export default function Popup({ instance = instanceDefault, element, children, title, onClose = noop }) {
  return ReactDOM.createPortal(
    <Container>
      <Header>
        <h3>{title}</h3>
        <CloseButton onClick={() => { instance.remove(); onClose() }}>
          <img src={close} alt="Close project details" />
        </CloseButton>
      </Header>
      <Content>{children}</Content>
    </Container>,
    element
  );
}