import styled from 'styled-components';
import { Button, TextField } from '../../UI';

export const Wrapper = styled.div`
  height: 520px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 80px;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fonts.xlarge.size}px;
  line-height: ${({ theme }) => theme.fonts.xlarge.lineHeight}px;
  color: ${({ theme }) => theme.palette.primary};
  margin: 40px;
`;

export const Form = styled.form`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${TextField} {
    margin-bottom: 20px;
  }
`;

export const Submit = styled(Button)`

`;

export const FormWrapper = styled.div`
  flex: 1;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
