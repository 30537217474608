import React from 'react';
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Submit, Title, Form, FormWrapper } from './AuthModal.style';
import { TextField } from '../../UI';

function LoginForm({ onSubmit, submitting, actions }) {
  const { handleSubmit, register, errors } = useForm();
  return (
    <FormWrapper>
      <Title>Log in</Title>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <TextField
          name="email"
          label="Email address"
          variant="outlined"
          fullWidth
          size="small"
          inputRef={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Not a valid email address',
            },
          })}
          helperText={errors.email && errors.email.message}
          error={errors.email}
        />
        <TextField
          name="password"
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          size="small"
          inputRef={register({
            required: 'Required',
            pattern: {
              value: /.{8,}/i,
              message: 'Password must be at least 8 characters',
            },
          })}
          helperText={errors.password && errors.password.message}
          error={errors.password}
        />
        <Submit
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          {submitting ? <CircularProgress size={20} color="white" /> : 'SIGN IN'}
        </Submit>
      </Form>
    </FormWrapper>
  );
}

export default LoginForm;
