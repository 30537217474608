/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useCallback } from 'react';
// import propTypes from 'prop-types';
import ReactDOM from 'react-dom';

import close from '../../assets/img/close.png';
import './style.css';

const FullScreenTour = ({ children, closeFullscreen }) => {
  const [showEscapeMessage, setShowEscapeMessage] = useState(true);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        closeFullscreen();
      }
    },
    [closeFullscreen],
  );

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    setTimeout(() => setShowEscapeMessage(false), 2000);
  }, [escFunction]);

  const container = document.getElementById('fullscreen');

  return ReactDOM.createPortal(
    <div className="fullscreen-container">
      <button
        type="button"
        onClick={closeFullscreen}
        className="close-fullscreen"
      >
        <img src={close} alt="Close fullscreen" />
      </button>
      <div className={`esc-exit ${showEscapeMessage ? '' : 'invisible'}`}>
        <span>Press esc to exit full screen</span>
      </div>
      {children}
    </div>,
    container,
  );
};

// FullScreenTour.propTypes = {
//   children: propTypes.node.isRequired,
//   closeFullscreen: propTypes.func.isRequired,
// };

export default FullScreenTour;
