import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { ApolloProvider } from '@apollo/client';

import { AuthProvider } from './components/AuthProvider';
import Header from './components/Header';
import Nav from './components/Nav';
import Footer from './components/Footer';
import Routes from './routes';
import client from './apollo';

import { Wrapper } from './App.style';
import theme from './theme';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: white;
    color: #004E63;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  canvas:focus {
    outline: none;
  }
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <Router>
        <ApolloProvider client={client}>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <Wrapper>
                <Header />
                <Nav />
                <Routes />
                <Footer />
              </Wrapper>
            </AuthProvider>
          </ThemeProvider>
        </ApolloProvider>
      </Router>
    </>
  );
}

export default App;
