import styled from 'styled-components';

export { default as Link } from './Link';
export { default as Container } from './Container';
export { default as Modal } from './Modal';
export { default as TextField } from './TextField';

export const ExternalLink = styled.a`
  color: ${({ theme }) => theme.palette.primary};
  text-decoration: none;
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(1, 5)};
  background: ${({ theme, color }) => (color ? theme.palette[color] : theme.palette.primary)};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  font-weight: 500;
  font-size: ${({ theme }) => theme.fonts.default.size}px;
  ${({ fullWidth }) => (fullWidth ? 'width: 100%' : '')};
`;

export const Paper = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  padding: 20px;
  position: relative;
`;
