import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import { gql, useMutation } from '@apollo/client';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';

import vucitySVG from '../../assets/Vucity.svg';
import { Button, TextField, Modal } from '../../components/UI';
import {
  Container,
  Title,
  Form,
  ModalWrapper,
  FileInput,
  Alert,
} from './Upload.style';

const SUBMIT_MODULE = gql`
  mutation(
    $payload: String!,
    $name: String!,
    $fileName: String!, 
    $description: String!,
    $price: Float!
  ) {
    createModule(
      payload: $payload,
      name: $name,
      description: $description,
      price: $price,
      fileName: $fileName
    ) {
      id
      name
    }
  }
`;

const toBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export default function SellerHome({ history }) {
  const { handleSubmit, register, errors } = useForm();

  const [createModule, { loading, data, error }] = useMutation(
    SUBMIT_MODULE,
    { onError: console.log },
  );
  const [file, setFile] = useState();

  const submitModule = useCallback(
    async ({ name, description, price }) => {
      const payload = await toBase64(file);
      createModule({
        variables: {
          name,
          description,
          payload,
          price: +price,
          fileName: file.name,
        },
      });
    },
    [file, createModule],
  );

  const handleChangeFile = useCallback(
    ({ target: { validity, files: [f] } }) => {
      if (validity.valid) setFile(f);
    },
    [setFile],
  );

  // Leaving the graphql implementation in, in case we reuse this with a real apollo server
  // It fails in this project due to the backend being a lambda function where multi part
  // file uploads are not allowed
  //
  // const handleSubmitWare = useCallback(
  //   ({ name, description, price }) => {
  //     submitModule({ variables: { name, description, price: +price, file } });
  //   },
  //   [submitModule, file],
  // );

  const navBack = useCallback(
    () => history.replace('/sell'),
    [history],
  );

  return (
    <Container>
      <Modal open={!!get(data, ['createModule', 'id'])} onClose={navBack}>
        <ModalWrapper>
          <img width="220px" src={vucitySVG} alt="VU.CITY" />
          <h1>Congratulations!</h1>
          <Title>Your ware has been submitted for approval</Title>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                onClick={navBack}
                color="secondary"
              >
                DASHBOARD
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={window.location.reload}
              >
                ADD ANOTHER
              </Button>
            </Grid>
          </Grid>
        </ModalWrapper>
      </Modal>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Title>Add New Wares</Title>
          <Form noValidate onSubmit={handleSubmit(submitModule)}>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              size="small"
              inputRef={register({
                required: 'Required',
              })}
              helperText={errors.name && errors.name.message}
              error={errors.name}
            />
            <TextField
              name="price"
              label="Price"
              variant="outlined"
              fullWidth
              size="small"
              inputRef={register({
                required: 'Required',
                pattern: {
                  value: /[0-9]\./i,
                  message: 'Price entered doesn\'t appear valid',
                },
              })}
              helperText={errors.price && errors.price.message}
              error={errors.price}
            />
            <TextField
              name="description"
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              size="small"
              inputRef={register({
                required: 'Required',
                pattern: {
                  value: /.{8,}/i,
                  message: 'Description must be more than x characters',
                },
              })}
              helperText={errors.description && errors.description.message}
              error={errors.description}
            />
            <FileInput type="file" required onChange={handleChangeFile} />
            {error && (
              <Alert severity="error">Module submission failed</Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  onClick={navBack}
                  color="secondary"
                >
                  BACK
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={20} color="white" /> : 'SUBMIT FOR APPROVAL'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
        <Grid item sm={6}>
          <Title>Submission Guidelines</Title>
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
          </p>
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
          </p>
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
          </p>
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
          </p>
          <p>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
          </p>
        </Grid>
      </Grid>
    </Container>
  );
}
