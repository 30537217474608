import React, { useState, useCallback, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import get from 'lodash/get';
import { Switch, Route, useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

import { useAuthContext } from '../../hooks';
import VuMap from '../../components/VuMap';
// import Filter from '../../components/Filter';
// import ProjectDetail from '../../components/ProjectDetail';

import config from '../../config';

const Wrapper = ({ children }) => (
  <div>
    {children}
  </div>
);

const GET_PROJECTS = gql`
  query {
    currentUser {
      projects {
        name
        id
        location {
          coords {
            x
            y
          }
        }
        media {
          name
          url
          type
          location {
            coords {
              x
              y
            }
          }
        }
      }
    }
  }
`;

const Projects = () => {
  const { email } = useAuthContext();
  const history = useHistory();
  const [mode, setMode] = useState('map');
  const [style, setStyle] = useState('VU.CITY');
  const [filters, setFilters] = useState({
    type: config.rbkc.types.priority.reduce((acc, type) => ({ ...acc, [type]: true }), {}),
    status: config.rbkc.statuses.priority.reduce((acc, status) => ({ ...acc, [status]: true }), {}),
    media: { tour: true, cgi: true, film: true, none: true, },
    consult: { red: true, yellow: true, green: true, blue: true },
  });
  const [value, setValue] = useState('');

  // Handle search functionality
  const updateValue = useCallback(
    (e) => setValue(e.target.value),
    [setValue],
  );

  // Handle project click
  const handleProjectClick = useCallback(
    (project) => {
      history.push(`/map/${project.id}`);
    },
    [history.push],
  );

  const { loading, error, data } = useQuery(
    GET_PROJECTS,
    {
      errorPolicy: 'all',
      variables: {
        email, // The query doesn't actually use this but we want to refetch if login status changes
      },
    },
  );

  const projects = useMemo(
    () => get(data, ['currentUser', 'projects'], []),
    [data],
  );

  if (loading) return <Wrapper><p>Loading...</p></Wrapper>;

  if (error) {
    return (
      <Wrapper>
        <Alert severity="error">
          User projects and media fetch failed with {error.message}
          <p>{get(error, ['graphQLErrors', 0, 'extensions', 'response', 'body'])}</p>
        </Alert>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {mode === 'map' && (
        <VuMap
          projects={projects}
          handleProjectClick={handleProjectClick}
          style={style}
        />
      )}

      <Switch>
        <Route path="/map/:projectId" component={() => null} />
      </Switch>
    </Wrapper>
  );
};

export default Projects;
