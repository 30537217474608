import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100vh - 160px);
  overflow: hidden;
  position: relative;

  > div {
    height: 100%;
  }

  .mapboxgl-popup-content {
    padding: 0 !important;
  }

  .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    border-bottom-color: ${({ theme }) => theme.palette.primary};
  }
`;
