import styled from 'styled-components';
import { Link } from '../UI';

export const Wrapper = styled.header`
  height: 88px;
  box-shadow: -2px 10px 50px rgba(0, 0, 0, 0.14);
  z-index: 10;
  position:relative;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Logo = styled.img`
`;

export const Toolbar = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  ${Link}, a {
    margin-right: 10px;
    &:last-of-type {
      margin: 0;
    }
  }
`;
