import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  top: 0;
`;

export const Box = styled.div`
  max-width: 90vw;
  max-height: 90vh;
  background: ${({ theme }) => theme.palette.altBackground};
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
`;
