import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Projects from './map';
import Sell from './sell';
import Upload from './upload';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/map" />
      </Route>
      <Route path="/map" component={Projects} />
      <Route path="/sell/upload" component={Upload} />
      <Route path="/sell" component={Sell} />
    </Switch>
  );
}
