import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import * as geolib from 'geolib';

import cgiPin from '../../assets/img/CGI.png';
import videoPin from '../../assets/img/Film.png';

import { domain } from '../../config';
import tourPin from '../../assets/img/360.png';
import Images from '../Images';
import PanoImage from '../PanoImage';
import VideoItem from '../VideoItem';
import Popup from './Popup';
import Scene from './Popup/Scene';
import bgStyles from './bgStyles.json';

import { Wrapper } from './VuMap.style';

import 'mapbox-gl/src/css/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

mapboxgl.accessToken = 'pk.eyJ1IjoidnVjaXR5IiwiYSI6ImNrYjBzOGwyOTA2dDEyc21uaGt1dm15ZmYifQ.fs9maDUm2LaK1h2c_ir4ZQ';

// TODO: Remove in favour of bounding map to projects on load
const lat = 51.49803;
const lng = -0.25815;

let map = null;

const findByName = (items, name) => items.filter((item) => (name === item.name));

const fixCoords = ({ x, y }) => ({ latitude: y, longitude: x });

function VuMap({ projects, display, handleProjectClick, style }) {
  const [popup, setPopup] = useState();

  const [selectedItem, setSelectedItem] = useState({});

  const mapContainer = useRef(null);

  // TODO: Check whether the div should be memoised
  const popUpContainer = useRef(document.createElement('div'));

  const geojson = useMemo(
    () => {
      const items = [];

      // Convert to geojson (1 property : 1 feature)
      const properties = projects
        .map((project) => {
          if (project.media) {
            project.media
              .forEach((item) => {
                const altImage = findByName(project.media, item.name).find(({ id }) => id !== item.id);
                // Add the 'existing' image as altUrl field if there is one
                const altUrl = altImage ? `${domain}${altImage.url}` : undefined;
                items.push({ ...item, altUrl, project });
              });
          }
          return {
            type: 'Feature',
            properties: project,
            geometry: {
              type: 'Point',
              coordinates: [project.location.coords.x, project.location.coords.y],
            },
          };
        });

      // TODO: Group by lat/lng and pass all images to Popup
      const itemFeatures = items
        .map((item) => ({
          type: 'Feature',
          properties: {
            ...item,
            rotation: geolib.getGreatCircleBearing(
              fixCoords(item.project.location.coords),
              fixCoords(item.location.coords),
            ),
          },
          geometry: {
            type: 'Point',
            coordinates: [item.location.coords.x, item.location.coords.y],
          },
        }));

      return {
        properties: {
          type: 'FeatureCollection',
          features: properties,
        },
        items: {
          type: 'FeatureCollection',
          features: itemFeatures,
        },
        tours: { type: 'FeatureCollection', features: [] },
      };
    },
    [projects],
  );

  const handleClosePopup = useCallback(
    () => {
      setSelectedItem({});
      map.easeTo({
        pitch: 0,
        bearing: 0,
        essential: true,
      });
    },
    [setSelectedItem],
  );

  const setCursor = useCallback(
    (type) => {
      map.getCanvas().style.cursor = type;
    },
    [],
  );

  const loadImages = useCallback(
    () => {
      if (!map.hasImage('IMAGE')) map.loadImage(
        cgiPin,
        (error, image) => {
          if (error) throw error;
          map.addImage('IMAGE', image);
        },
      );

      if (!map.hasImage('VIDEO')) map.loadImage(
        videoPin,
        (error, image) => {
          if (error) throw error;
          map.addImage('VIDEO', image);
        },
      );

      if (!map.hasImage('PANO')) map.loadImage(
        tourPin,
        (error, image) => {
          if (error) throw error;
          map.addImage('PANO', image);
        },
      );
    },
    [],
  );

  const addLayers = useCallback(
    () => {
      if (map.getLayer('item')) return;

      map.addSource('items', {
        type: 'geojson',
        data: geojson.items,
      });

      map.addLayer({
        id: 'item',
        type: 'symbol',
        source: 'items',
        layout: {
          'icon-image': ['get', 'type'],
          'icon-size': 0.4,
          'icon-anchor': 'bottom',
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
        },
      });
    },
    [geojson],
  );

  useEffect(
    () => {
      if (!map) return;
      map.setStyle(bgStyles.find((bg) => bg.name === style).style);
    },
    [style],
  );

  // Refresh the map source and recenter if geojson changes (foucused project)
  useEffect(
    () => {
      if (!map || !map.getSource('items')) return;
      map.getSource('items').setData(geojson.items);
    },
    [geojson],
  );

  // Initialise the map
  useEffect(
    () => {
      map = new mapboxgl.Map({
        container: mapContainer.current,
        style: bgStyles.find((bg) => bg.name === style).style,
        center: [lng, lat],
        zoom: 11,
      });
      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl,
        }),
        'top-right',
      );

      loadImages();

      // Triggered after map style is changed. When this happens all the sources, layers and images
      // are removed so have to be reloaded
      // map.on('', () => {
      //   loadImages();
      //   addLayers();
      // });

      map.on('style.load', () => {
        addLayers();

        // Handle individual property feature click
        map.on('click', 'item', (e) => {
          const { properties, geometry } = e.features[0];
          map.easeTo({
            center: geometry.coordinates,
            pitch: properties.type === 'IMAGE' ? 60 : 0,
            bearing: properties.type === 'IMAGE' ? (properties.rotation + 180) % 360 : 0,
          });

          const pop = new mapboxgl.Popup({ closeButton: false, anchor: 'top' })
            .setLngLat(geometry.coordinates)
            .setDOMContent(popUpContainer.current)
            .setMaxWidth('300px')
            .addTo(map);
          setPopup(pop);
          setSelectedItem({ ...properties });
        });

        map.on('mouseenter', 'item', (e) => {
          setCursor('pointer');
        });

        map.on('mouseleave', 'item', () => {
          setCursor('');
        });
      });

      map.addControl(new mapboxgl.NavigationControl());
    },
    [addLayers, handleProjectClick, loadImages, setCursor, style],
  );

  // Refresh the map source and recenter if projects has changed (filters set/unset)
  useEffect(
    () => {
      if (!map || !geojson.items.features.length) return;

      const { minLat, maxLat, minLng, maxLng } = geolib.getBounds(
        geojson.items.features.map(
          (feature) => ({
            latitude: feature.geometry.coordinates[0],
            longitude: feature.geometry.coordinates[1],
          }),
        ),
      );
      map.fitBounds(
        [[minLat, minLng], [maxLat, maxLng]],
        { padding: 100, maxZoom: 15 },
      );
    },
    [geojson.items.features, projects],
  );

  return (
    <Wrapper>
      <div
        id="map"
        ref={mapContainer}
      />
      <div id="menuContainer">
        <div id="menu" />
      </div>
      <nav id="bgmenu" />
      <Popup
        instance={popup}
        element={popUpContainer.current}
        title={selectedItem.name}
        onClose={handleClosePopup}
      >
        {selectedItem.type === 'IMAGE' && <Images items={[selectedItem]} showFirst />}
        {selectedItem.type === 'PANO' && <PanoImage {...selectedItem} />}
        {selectedItem.type === 'VIDEO' && <VideoItem {...selectedItem} />}
      </Popup>
    </Wrapper>
  );
}

// VuMap.propTypes = {
//   projects: PropTypes.arrayOf(PropTypes.object),
//   handleProjectClick: PropTypes.func.isRequired,
//   display: PropTypes.bool,
//   style: PropTypes.string.isRequired,
// };

// VuMap.defaultProps = {
//   projects: [],
//   display: false,
// };

export default VuMap;
