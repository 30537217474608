import styled from 'styled-components';

export const Wrapper = styled.header`
  background: ${({ theme }) => theme.palette.primary};
  height: 36px;
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    color: white;
    text-decoration: none;
    font-size: ${({ theme }) => theme.fonts.small.size}px;
  }
`;
