const colors = {
  petrol: '#004E63',
  white: 'white',
  lightGray: '#F2F2F2',
  gray: '#4E5758',
  otherGray: '#D2D2D2',
};

export default {
  colors,
  spacing: (...vals) => vals.map((val) => `${val * 4}px`).join(' '),
  palette: {
    primary: colors.petrol,
    secondary: colors.gray,
    background: colors.white,
    altBackground: colors.lightGray,
  },
  fonts: {
    xxsmall: {
      size: 10,
      lineHeight: 16,
    },
    xsmall: {
      size: 12,
      lineHeight: 16,
    },
    small: {
      size: 14,
      lineHeight: 16,
    },
    default: {
      size: 16,
      lineHeight: 19,
    },
    medium: {
      size: 22,
      lineHeight: 26,
    },
    large: {
      size: 26,
      lineHeight: 32,
    },
    xlarge: {
      size: 34,
      lineHeight: 40,
    },
  },
};
