import styled from 'styled-components';
import MuiContainer from '@material-ui/core/Container';
import MuiAlert from '@material-ui/lab/Alert';

import { Paper, TextField } from '../../components/UI';

export const Container = styled(MuiContainer)`
  background: white;
  padding: ${({ theme }) => theme.spacing(12, 0, 0, 0)};

  ${Paper} {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export const Heading = styled.div`
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fonts.xlarge.size}px;
  line-height: ${({ theme }) => theme.fonts.xlarge.lineHeight}px;
  font-weight: 300;
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.medium.size}px;
  line-height: ${({ theme }) => theme.fonts.medium.lineHeight}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  font-weight: 300;
`;

export const Form = styled.form`
  ${TextField} {
    margin-bottom: ${({ theme }) => theme.spacing(4)};

    &:first-of-type {
      margin-top: ${({ theme }) => theme.spacing(4)};
    }
  }
`;

export const ModalWrapper = styled.div`
  height: 520px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 80px;

  h1 {
    font-size: 60px;
    font-weight: 300;
    margin: 0;
  }

  ${Title} {
    text-align: center;
  }
`;

export const FileInput = styled.input`
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const Alert = styled(MuiAlert)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
