import React, { useCallback } from 'react';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as HomeIcon } from '../../assets/Home.svg';
import { ReactComponent as OrdersIcon } from '../../assets/Orders.svg';
import { ReactComponent as PriceTagIcon } from '../../assets/PriceTag.svg';
import { ReactComponent as PersonIcon } from '../../assets/Person.svg';
import { ReactComponent as BarChartIcon } from '../../assets/BarChart.svg';
import { ReactComponent as CogIcon } from '../../assets/Cog.svg';
import GraphImg from '../../assets/img/Graph.png';

import { Paper, Button } from '../../components/UI';
import {
  Container,
  Heading,
  Title,
  SubTitle,
  SideNav,
  NavItemLink,
  Image,
  Summary,
} from './Sell.style';

const NavItem = ({ label, Icon, navTo }) => (
  <NavItemLink to={navTo}>
    <Icon />
    {label}
  </NavItemLink>
);

export default function SellerHome({ history }) {
  const navToUpload = useCallback(
    () => history.push('/sell/upload'),
    [history],
  );

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <SideNav>
            <NavItem label="Home" Icon={HomeIcon} navTo="/sell" />
            <NavItem label="Orders" Icon={OrdersIcon} navTo="/sell/orders" />
            <NavItem label="Products" Icon={PriceTagIcon} navTo="/sell/products" />
            <NavItem label="Customers" Icon={PersonIcon} navTo="/sell/customers" />
            <NavItem label="Analytics" Icon={BarChartIcon} navTo="/sell/analytics" />
            <NavItem label="Settings" Icon={CogIcon} navTo="/sell/settings" />
          </SideNav>
        </Grid>
        <Grid item sm={9}>
          <Heading>
            <div>
              <Title>Good morning, Captain POC</Title>
              <SubTitle>Here's what's happening with your store today</SubTitle>
            </div>
            <Button onClick={navToUpload}>UPLOAD WARES</Button>
          </Heading>
          <Summary>
            <div>
              <h5>£2,543</h5>
              <h6>Today's sales</h6>
            </div>
            <div>
              <h5>24</h5>
              <h6>Today's orders</h6>
            </div>
            <div>
              <h5>340</h5>
              <h6>Today's visits</h6>
            </div>
          </Summary>
          <Paper>
            <Image src={GraphImg} />
          </Paper>
          <Grid container spacing={2}>
            <Grid item>
              <Paper>
                Sales
              </Paper>
            </Grid>
            <Grid item>
              <Paper>
                Customers
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
