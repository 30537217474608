import styled from 'styled-components';
import { Link as UILink } from '../UI';

export const Wrapper = styled.footer`
  background: ${({ theme }) => theme.palette.primary};
  height: 36px;
`;
export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  

  div {
    display: flex;
    align-items: center;
    color: white;
    font-size: 14px;
  }
`;

export const Logo = styled.img`
  height: 20px;
`;

export const Link = styled(UILink)`
  color: white;
`;
