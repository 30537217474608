import styled from 'styled-components';
import MuiContainer from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

import { Paper } from '../../components/UI';

export const Container = styled(MuiContainer)`
  background: white;
  padding: ${({ theme }) => theme.spacing(12, 0, 0, 0)};

  ${Paper} {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 300;
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fonts.xlarge.size}px;
  line-height: ${({ theme }) => theme.fonts.xlarge.lineHeight}px;
`;

export const SubTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.medium.size}px;
  line-height: ${({ theme }) => theme.fonts.medium.lineHeight}px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const NavItemLink = styled(Link)`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary};

  svg {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }

  &:last-of-type {
    margin: 0;
  }
`;

export const SideNav = styled(Paper)`
  ${NavItemLink}:last-of-type {
    margin-top: ${({ theme }) => theme.spacing(10)};
  }
`;

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const Summary = styled(Paper)`
  display: flex;
  flex-direction: row;

  > div {
    flex: 1;
    border-right: 1px solid ${({ theme }) => theme.colors.otherGray};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    h5, h6 {
      margin: 0;
    }

    h5 {
      font-size: ${({ theme }) => theme.fonts.large.lineHeight}px;
      font-weight: 600;
    }

    h6 {
      font-size: ${({ theme }) => theme.fonts.xsmall.lineHeight}px;
      font-weight: 400;
    }

    &:last-of-type {
      border: none;
    }
  }
`;
