export const domain = 'https://vurservermvc20200917105018.azurewebsites.net';

const typeDefs = {
  PP: {
    title: 'Planning permission',
    description: 'Application for planning permission',
  },
  CA: {
    title: 'Control of advert',
    description: 'Application for consent to display an advert',
  },
  CON: {
    title: 'Conditions',
    description: 'Application for approval of details reserved by condition',
  },
  LB: {
    title: 'Listed building consent',
    description: 'Application for listed building consent',
  },
  CL: {
    title: 'Lawful development certificate',
    description: 'Application to ascertain whether an existing or proposed use of buildings or land, or development on the land, is lawful',
  },
  NMA: {
    title: 'Non material amendment',
    description: 'Application to make non-material changes to a planning permission',
  },
  PC: {
    title: 'Pavement crossover',
    description: 'Application for a pavement crossover',
  },
  PA: {
    title: 'Prior approval',
    description: 'Application to determine if the Council’s prior approval is required for certain permitted developments',
  },
};

const typePriority = ['PP', 'CA', 'PC', 'NMA', 'CON', 'PA', 'LB', 'CL'];

const statusDefs = {
  REG: {
    title: 'Registered',
    description: 'Application under consideration',
  },
  APD: {
    title: 'Appeal decided',
    description: 'Council’s decision has been appealed and the appeal has been decided',
  },
  PDE: {
    title: 'Pre-determination stage',
    description: 'Application under consideration',
  },
  APS: {
    title: 'Appeal received',
    description: 'Council’s decision has been appealed',
  },
  DEX: {
    title: 'DEX',
    description: 'TODO',
  },
};

const statusPriority = ['REG', 'APD', 'PDE', 'APS', 'DEX'];

export default {
  urls: {
    backendApi: process.env.DOTNET_SERVER_URL || 'https://vurservermvc20200917105018.azurewebsites.net'
  },
  rbkc: {
    url: 'https://www.rbkc.gov.uk',
    types: {
      priority: typePriority,
      defs: typeDefs,
      list: typePriority.map((type) => ({ key: type, ...typeDefs[type] })),
    },
    statuses: {
      priority: statusPriority,
      defs: statusDefs,
      list: statusPriority.map((status) => ({ key: status, ...statusDefs[status] })),
    }
  }
};
