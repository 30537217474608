import ReactDOM from 'react-dom';
import React, { useEffect } from 'react';
import { useClickOutside } from 'react-click-outside-hook';

import { Box, Container } from './Modal.style';

const ClickOutside = ({ children, onClose }) => {
  const [ref, hasClickedOutside] = useClickOutside();
  useEffect(
    () => {
      if (hasClickedOutside) onClose();
    },
    [hasClickedOutside, onClose],
  );
  const container = document.getElementById('fullscreen');
  // console.log('Fullscreen render')
  return ReactDOM.createPortal(
    <Container>
      <Box ref={ref}>
        {children}
      </Box>
    </Container>,
    container,
  );
};

export default function CustomizedDialogs({ children, title, onClose, open }) {
  if (!open) return null;
  return (
    <ClickOutside onClose={onClose}>
      {children}
    </ClickOutside>
  );
}
