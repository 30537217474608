import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import UserIcon from '@material-ui/icons/Person';
import Avatar from '@material-ui/core/Avatar';
import SearchIcon from '@material-ui/icons/Search';

import { useAuthContext } from '../../hooks';
import vucitySVG from '../../assets/Vucity.svg';

// import { ReactComponent as CartSVG } from '../../assets/Cart.svg';

import { Logo, Wrapper, Toolbar, Content } from './Nav.style';
import { Link, ExternalLink, Container } from '../UI';

export default function Nav() {
  const { loggedIn, actions, email } = useAuthContext();
  return (
    <Wrapper>
      <Container>
        <Content>
          <Logo src={vucitySVG} alt="VU.CITY" />
          <Toolbar>
            <ExternalLink href="https://store.vu.city/" target="_blank">
              Buy
            </ExternalLink>
            <Link to="/sell">Sell</Link>
            <Link to="/map">Map</Link>
            <IconButton>
              <SearchIcon />
            </IconButton>
            <IconButton onClick={actions.showModal} aria-label="delete" edge="end">
              {loggedIn ? <Avatar>{email.slice(0, 1).toUpperCase()}</Avatar> : <UserIcon />}
            </IconButton>
          </Toolbar>
        </Content>
      </Container>
    </Wrapper>
  );
}
