import React from 'react';

import vucitySVG from '../../../assets/Vucity.svg';
import { Modal, Button } from '../../UI';

import { Wrapper } from './AuthModal.style';
import LoginForm from './LoginForm';

const AuthModal = ({ onClose, mode, email, error, actions, initialValues, pending }) => (
  <Modal open={!!mode} onClose={onClose}>
    <Wrapper>
      <img width="220px" src={vucitySVG} alt="VU.CITY" />
      {mode === 'login' && <LoginForm initialValues={initialValues} onSubmit={actions.login} actions={actions} submitting={pending} />}
      {mode === 'logout' && (
        <>
          {`Logged in as ${email}`}
          <Button fullWidth type="submit" onClick={actions.logout}>LOGOUT</Button>
        </>
      )}
      {error && <div>{error}</div>}
    </Wrapper>
  </Modal>
);

export default AuthModal;
