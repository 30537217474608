import React from 'react';

import { Wrapper, Content } from './Header.style';
import { Container } from '../UI';

export default function Nav() {
  return (
    <Wrapper>
      <Container>
        <Content>
          <a
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help & Support
          </a>
        </Content>
      </Container>
    </Wrapper>
  );
}
