import React from 'react';
import logo from '../../assets/Vucity.png';

import { Content, Wrapper, Link, Logo } from './Footer.style';
import { Container } from '../UI';

export default function Footer() {
  return (
    <Wrapper>
      <Container>
        <Content>
          <div>
            © 2020 All rights reserved VUCITY.
          </div>
          <div>
            <Link to="/privacy">
              Privacy Policy
            </Link>
            &nbsp;|&nbsp;
            <Link to="/cookie">
              Cookie Policy
            </Link>
          </div>
          <div>
            Powered by <Logo src={logo} alt="logo" />
          </div>
        </Content>
      </Container>
    </Wrapper>
  );
}
