import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';

import parseJWT from 'jwt-decode';

import AuthModal from './AuthModal';

const initialState = {
  email: '',
  pending: false,
  error: '',
  loggedIn: false,
};

const AuthContext = React.createContext();

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

export function AuthProvider({ children }) {
  const [state, setState] = useState(initialState);
  const [mode, setMode] = useState(null);
  const [initialFormValues, setInitialFormValues] = useState({});
  const [loginUser] = useMutation(LOGIN);

  const setJWT = useCallback(
    (jwt, prevState, nextState = {}) => {
      localStorage.setItem('token', jwt);
      const claims = parseJWT(jwt);
      setState({
        ...prevState,
        ...nextState,
        ...claims,
        email: claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'], // Don't ask..
        loggedIn: true,
        error: '',
        jwt,
      });
      return claims;
    },
    [setState],
  );

  // Get cached jwt and decode into state on mount
  useEffect(() => {
    const jwt = localStorage.getItem('token');
    if (!jwt) return;
    setJWT(jwt, initialState);
  }, [setJWT]);

  const showModal = useCallback(
    (nextMode, initialValues) => {
      setState({
        ...state,
        error: '',
      });
      // Close modal if null passed
      if (nextMode === null) return setMode(null);

      // We can't use a default param here as this can be passed directly
      // to onClick which would pass an event
      const defaultMode = state.email ? 'logout' : 'login';
      if (initialValues) setInitialFormValues(initialValues);
      return setMode(typeof nextMode === 'string' ? nextMode : defaultMode);
    },
    [state],
  );

  const login = useCallback(
    async (creds) => {
      setState({
        ...state,
        pending: true,
        error: '',
      });
      try {
        const response = await loginUser({ variables: creds });
        setJWT(response.data.login.token, state, { pending: false });
        setMode(null);
      }
      catch (e) {
        setState({
          ...state,
          pending: false,
          error: 'Something went wrong when logging in',
        });
      }
    },
    [state, setJWT, loginUser],
  );

  const logout = useCallback(
    async () => {
      localStorage.removeItem('token');
      await window.apolloClient.clearStore();
      await window.apolloClient.resetStore();
      setState(initialState);
      setMode(null);
    },
    [],
  );

  // const refreshJWT = useCallback(
  //   async () => {
  //     try {
  //       const { data: jwt } = await axios('/.netlify/functions/auth/refresh');
  //       setJWT(jwt, state, { loggedIn: true, pending: false });
  //     }
  //     catch (e) {
  //       await logout();
  //       showModal('login');
  //     }
  //   },
  //   [setState],
  // );

  const actions = useMemo(
    () => ({
      login,
      logout,
      showModal,
    }),
    [login, logout, showModal],
  );

  return (
    <AuthContext.Provider value={{ ...state, loggedIn: !!state.email, actions }}>
      <AuthModal
        onClose={() => setMode(null)}
        actions={actions}
        mode={mode}
        initialValues={initialFormValues}
        {...state}
      />
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
